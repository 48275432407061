import { Form, Input, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../utils/auth';

const Login = () => {
  const { login } = useAuth()
  const history = useHistory()
  const onFinish = (values) => {
    const { username, password } = values
    login({ username, password })
    history.push('/')
    // console.log('Success:', values);
  };
  // console.log({ user })
  // if (user) {
  //   return <Redirect to="/" />
  // }

  // const onFinishFailed = (errorInfo) => {
  //   console.log('Failed:', errorInfo);
  // };
  return <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 8,
    }}
    style={{ margin: '2em' }}
    onFinish={onFinish}
    // onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="ชื่อผู้ใข้"
      name="username"
      rules={[
        {
          required: true,
          message: 'Please input your username!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="รหัสผ่าน"
      name="password"
      rules={[
        {
          required: true,
          message: 'Please input your password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>
    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 8,
      }}
    >
      <Button type="primary" htmlType="submit">
        เข้าสู่ระบบ
      </Button>
    </Form.Item>
  </Form>
}

export default Login
