import React, { useContext, useEffect, useRef, useState } from 'react'
import useScript from './useScript'
import './tableau.css'
// const TableauServer = 

const TableauServerContext = React.createContext({})
const TableauContainerContext = React.createContext()

const TableauServer = ({ children, version, url, site, defaultOptions }) => {
  return <TableauServerContext.Provider value={{ version, url, site, defaultOptions }}>
    {children}
  </TableauServerContext.Provider>
}

const TableauContainer = ({ children }) => {
  const ref = useContext(TableauContainerContext)
  return <div ref={ref} className="tableau-container">{children}</div>
}

const TableauView = ({ workBook, view, filters, parameters, options, onLoad, children, containerStyle }) => {
  // console.log({ workBook, view })
  const { version, url, site, defaultOptions } = useContext(TableauServerContext)
  const [loaded, setLoaded] = useState(false)
  const containerRef = useRef()
  const vizRef = useRef()
  const status = useScript(`${url}/javascripts/api/tableau-${version ?? 2}.min.js`)
  // useMemo()
  // eslint-disable-next-line no-undef
  const createViz = ({ workBook, view, options }) => new tableau.Viz(containerRef.current, `${url}${site ? `/t/${site}` : ''}/views/${workBook}/${view}`, {
    ...defaultOptions,
    ...options,
    // _org_level1: ['สำนักงานทางหลวงชนบทที่ 1 (ปทุมธานี)'],
    // _org_level1: [],
    // _org_level2: ['บทช.สมโภชเชียงใหม่700ปี'],
    // _org_level1: 'สำนักงานทางหลวงชนบทที่ 1 (ปทุมธานี)',
    onFirstInteractive: () => {
      // console.log('complete')
      setLoaded(true)
    }
  })
  useEffect(() => {
    if (status === 'ready') {
      console.log('ready')
      vizRef.current = createViz({ workBook, view, options })
    }
    return () => {
      vizRef.current?.dispose()
    }
  }, [status, workBook, view, options]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {

  }, [workBook, view])

  if (status !== 'ready') {
    return 'Loading...'
  }
  return (
    <TableauContainerContext.Provider value={containerRef}>
      {children
        ? loaded && children({ viz: vizRef.current })
        : <TableauContainer style={containerStyle} />
      }
    </TableauContainerContext.Provider>
  )
}

const Tableau = {
  Server: TableauServer,
  View: TableauView,
  Container: TableauContainer
}

export default Tableau