import React, { useState, useContext } from 'react'
// import { useHistory } from 'react-router'
import users from '../users.json'

const USER_KEY = '__user'

// const TableauServer = 
// const TableauServerContext = React.createContext({})
const AuthContext = React.createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const _u = localStorage.getItem(USER_KEY)
  const [user, setUser] = useState(_u && JSON.parse(_u))
  // console.log({ user })
  const logout = () => {
    setLoading(true)
    localStorage.removeItem(USER_KEY)
    setUser()
    setLoading(false)
  }
  const login = ({ username, password }) => {
    setLoading(true)
    const user = users.find(u => u.id === username)
    if (!user) {
      throw new Error('INCORRECT_AUTH')
    }
    localStorage.setItem(USER_KEY, JSON.stringify(user))
    setUser(user)
    setLoading(false)
  }
  // const history = useHistory()
  // useEffect(() => {
  //   if(!user) {
  //     history?.push('/login')
  //   }
  // }, [user, history])
  return (
    <AuthContext.Provider value={{ loading, login, logout, user }} >
      {children}
    </AuthContext.Provider>
  )
}