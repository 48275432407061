import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Tableau from './utils/Tableau';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider, useAuth } from './utils/auth';
// import { Redirect } from 'react-router-dom';
import Login from './pages/Login';

const TableauApp = () => {
  const { loading, user } = useAuth()
  if (loading) {
    return '...Loading...'
  }
  if (!user) {
    // return <Redirect to="/login" />
    return <Login />
  }
  return <Tableau.Server
    version={process.env.REACT_APP_TABLEAU_SERVER_VERSION}
    url={process.env.REACT_APP_TABLEAU_SERVER_URL}
    site={process.env.REACT_APP_TABLEAU_SERVER_SITE}
    defaultOptions={{
      hideTabs: true,
      hideToolbar: true,
      device: 'desktop',
      ...user?.permissions
    }}
  >
    <App />
  </Tableau.Server>
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <TableauApp />
      </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
