import React, { useState } from 'react'
import { Menu, Layout, Button, Drawer, Space } from 'antd'
import {
  MenuOutlined
} from '@ant-design/icons'
import qs from 'qs'
import { Switch, Route, Link } from 'react-router-dom'
import Tableau from './utils/Tableau'
import ThingsBoardRedirect from './components/ThingsBoardRedirect'
// import Login from './pages/Login'
import { useAuth } from './utils/auth'
// import logo from './logo.svg'
// import './App.css'
// rgb(24, 144, 255)

// 1.1 ด้านก่อสร้างทาง

// 1.2 ด้านบำรุงทาง

// 1.3 ด้านความปลอดภัย

// 2.1 ต้นทุนโลจิสติกส์
// 2.2 จำนวนอุบัติเหตุ
// 2.3 ด้านสิ่งแวดล้อม
// 2.4 ด้านอื่นๆ

// 3.1 ก่อสร้างทาง

// 3.2 บำรุงทาง

// 3.3 ด้านความปลอดภัย

// 3.1.1 แผน-ผล การจัดซื้อจัดจ้าง
// 3.1.2 แผน-ผล การเบิกจ่าย

// 3.2.1 แผน-ผล การจัดซื้อจัดจ้าง
// 3.2.2 แผน-ผล การเบิกจ่าย

// 3.3.1 แผน-ผล การจัดซื้อจัดจ้าง
// 3.3.2 แผน-ผล การเบิกจ่าย


// 3.4 ประสิทธิภาพการดำเนินงาน

// 3.4.1 เรื่องร้องเรียน
// 3.4.2  จำนวนระบบที่ได้รับการพัฒนา

const items = [{
  title: 'มิติที่ 1 มิติด้านประสิทธิผล',
  subItems: [{
    title: '1.1 ด้านก่อสร้างทาง',
    path: '/topics/Project_Efficiency/reports/Project_Efficiency?f[act_type_filter]=ก่อสร้าง'
  }, {
    title: '1.2 ด้านบำรุงทาง',
    path: '/topics/Project_Efficiency/reports/Project_Efficiency?f[act_type_filter]=บำรุงทาง'
  }, {
    title: '1.3 ด้านความปลอดภัย',
    path: '/topics/Project_Efficiency/reports/Project_Efficiency?f[act_type_filter]=ความปลอดภัย'
  }]
}, {
  title: 'มิติที่ 2 มิติด้านคุณภาพ',
  subItems: [{
    title: '2.1 ต้นทุนโลจิสติกส์',
    path: '/topics/Logistics_Environment_Index/reports/Logistics_Environment_Index'
  }, {
    title: '2.2 จำนวนอุบัติเหตุ',
    path: '/topics/Road_Accidents/reports/Road_Accidents'
  }, {
    title: '2.3 ด้านสิ่งแวดล้อม',
    path: {
      pathname: 'http://iot.sappasing.com/dashboards/c9d73a30-d716-11eb-8893-4b67d781275d'
    }
  }, {
    title: '2.4 ด้านอื่นๆ',
    path: '',
    subItems: [{
      title: '2.4.1 คุณภาพสายทาง',
      path: '/topics/Route_Score/reports/Route_Score'
    }, {
      title: '2.4.2 การมีส่วนร่วม',
      path: '/topics/Volunteer/reports/Volunteer'
    }]
  }]
}, {
  title: 'มิติที่ 3 มิติด้านประสิทธิภาพ',
  subItems: [{
    title: '3.1 ก่อสร้างทาง',

    // https://drr.logicspace.co.th/topics/Monthly_Budget_Plan/reports/Monthly_Budget_Plan
    // https://drr.logicspace.co.th/topics/Monthly_Results_by_Progress/reports/Monthly_Results_by_Progress
    subItems: [{
    //   title: '3.1.1 แผน-ผล การจัดซื้อจัดจ้าง',
    //   path: ''
    // }, {
      title: '3.1.1 แผน-ผล การเบิกจ่าย',
      path: '/topics/Project_Effectiveness/reports/Project_Effectiveness?f[act_type_filter]=ก่อสร้าง'
    }]
  }, {
    title: '3.2 บำรุงทาง',
    subItems: [{
    //   title: '3.2.1 แผน-ผล การจัดซื้อจัดจ้าง',
    //   path: ''
    // }, {
      title: '3.2.1 แผน-ผล การเบิกจ่าย',
      path: '/topics/Project_Effectiveness/reports/Project_Effectiveness?f[act_type_filter]=บำรุงทาง'
    }]
  }, {
    title: '3.3 ด้านความปลอดภัย',
    subItems: [{
    //   title: '3.3.1 แผน-ผล การจัดซื้อจัดจ้าง',
    //   path: ''
    // }, {
      title: '3.3.1 แผน-ผล การเบิกจ่าย',
      path: '/topics/Project_Effectiveness/reports/Project_Effectiveness?f[act_type_filter]=ความปลอดภัย'
    }]
  }, {
    title: '3.4 ประสิทธิภาพการดำเนินงาน',
    subItems: [{
    //   title: '3.4.1 เรื่องร้องเรียน',
    //   path: ''
    // }, {
      title: '3.4.1  จำนวนระบบที่ได้รับการพัฒนา',
      path: '/topics/Systems_Status/reports/Systems_Status'
    }]
  }]
}, {
  title: 'มิติที่ 4 มิติด้านการพัฒนาองค์กร',
  subItems: [{
    title: '4.1 การพัฒนาบุคลากรภายในองค์กร',
    path: '/topics/PERSONNEL_PLAN/reports/PERSONNEL_PLAN'
  }, {
    title: '4.2 การพัฒนาบุคลากรด้าน ICT',
    path: '/topics/Personnel_Training/reports/Personnel_Training'
  }, {
    title: '4.3 การใช้เครื่องจักรเพื่อเพิ่มประสิทธิภาพการปฏิบัติงาน',
    subItems: [{
      title: '4.3.1 ร้อยละของเครื่องจักรของกรมทางหลวงชนบทที่มีสภาพการใช้งาน(ร้อยละของเครื่องจักรของกรมทางหลวงชนบทที่มีสภาพระดับ C ขึ้นไป)',
      path: '/topics/Vehicle/reports/Vehicle'
    }, {
      title: '4.3.2 ร้อยละของหน่วนงานที่มีเครื่องจักรของหน่วยงานต่างๆ และสภาพการใช้งานตามกรอบอัตรที่กำหนดไว้',
      path: '/topics/Vehicle/reports/Vehicle'
    }]
  }]
}, {
  title: 'รายงานแบ่งตามกลุ่มรายงาน',
  subItems: [{
    title: '1.งบประมาณและโครงการ',
    subItems: [{
      title: 'แผนการเบิกจ่ายงบประมาณ',
      path: '/topics/BUDGET_PLAN/reports/BUDGET_PLAN'
    }, {
      title: 'รายงานโครงการทั้งหมดของแต่ละสำนัก',
      path: '/topics/All_Projects/reports/All_Projects'
    }, {
      title: 'รายงานผลการดำเนินการของแต่ละโครงการตามความคืบหน้าของโครงการ',
      path: '/topics/Results_by_Progress/reports/Results_by_Progress'
    }, {
      title: 'รายงานแผนการดำเนินงานแต่ละโครงการในแต่ละเดือน',
      path: '/topics/Monthly_Results_by_Progress/reports/Monthly_Results_by_Progress'
    },
    // {
    //   title: 'รายงานแผนการเบิกจ่ายในแต่ละเดือน',
    //   path: '/topics/Monthly_Budget_Plan/reports/Monthly_Budget_Plan'
    // },
    {
      title: 'รายงานจัดซื้อจัดจ้างแยกรายสำนัก',
      path: '/topics/Bureau_abbr_budget/reports/Bureau_abbr_budget'
    }, {
      title: 'รายงานโครงการสายทางที่ได้รับการบำรุงทางแยกตามแผนงาน',
      path: '/topics/Bureau_abbr_budget_plan/reports/Bureau_abbr_budget_plan'
    }, {
      title: 'รายงานโครงการจัดซื้อจัดจ้าง',
      path: '/topics/Procurement/reports/Procurement'
    }, {
      title: 'รายงานสถานะโครงการ',
      path: '/topics/Project_Status/reports/Project_Status'
    }, {
      title: 'รายงานโครงการตามความคืบหน้าของโครงการ',
      path: '/topics/DiagnosticDashboards/reports/Diagnostic01',
      downloadSheet: 'Diagnostic01Data'
    }, {
      title: 'สาเหตุความล่าช้าของโครงการ',
      // path: '/topics/1_/reports/10_'
    }, {
      title: 'จำนวนโครงการที่คาดว่าเสร็จในแต่ละเดือน',
      path: '/topics/DiagnosticDashboards/reports/Diagnostic03'
    }, {
      title: 'รายงานโครงการที่มีโอกาสสำเร็จ',
      path: '/topics/ProjectDelayPrediction/reports/ProjectDelayPrediction'
    }, {
      title: 'รายงานโครงการที่มีโอกาสล่าช้า',
      // path: '/topics/1_/reports/13_'
    }, {
      title: 'สาเหตุของโครงการที่มีโอกาสล่าช้า',
      // path: '/topics/1_/reports/14_'
    }],
  }, {
    title: '2.มาตรฐานสายทาง',
    subItems: [{
      title: 'รายงานจำนวนสายทางที่มีมาตรฐานตาม ค่า iri',
      path: '/topics/iri/reports/iri'
      // }, {
      //   title: 'รายงานถนนที่มีระดับความเสี่ยงสูง',
      //   path: '/topics/High_Risk_Roads/reports/High_Risk_Roads'
      // }, {
      //   title: 'จำนวนสายทางที่ต้องได้รับการปรับปรุง',
      //   path: '/topics/grade_roads/reports/grade_roads'
    }, {
      title: 'จำนวนสายทางที่ต้องได้รับการปรับปรุง',
      path: '/topics/DiagnosticDashboards/reports/Diagnostic05'
    }],
  }, {
    title: '3.กลุ่มอุบัติเหตุ',
    subItems: [{
      title: 'รายงานสายทางที่มีจำนวนอุบัติเหตุมากที่สุด',
      path: '/topics/Road_Accidents/reports/Road_Accidents'
    }, {
      title: 'รายงานจำนวนผู้ประสบอุบัติเหตุแยกตามอาการบาดเจ็บแบ่งเป็นรายสำนัก',
      path: '/topics/Road_Accidents/reports/Road_Accidents'
    }, {
      title: 'รายงานการแก้ปัญหาความปลอดภัย IMPROVEMENT_SPECIFY',
      path: '/topics/Road_Accidents/reports/Road_Accidents'
    }],
  }, {
    title: '4.การบำรุงทาง',
    subItems: [{
      title: 'รายงานการบำรุงทาง',
      path: '/topics/RoadMaintenance/reports/RoadMaintenance'
      // }, {
      //   title: 'จำนวนระยะทางที่ได้รับการบำรุงทาง',
      //   path: '/topics/Road_Maintenance/reports/Road_Maintenance'
      // }, {
      //   title: 'งบประมาณในการบำรุงทางในแต่ละปี',
      //   path: '/topics/Road_Maintenance/reports/Road_Maintenance'
    }],
  }, {
    title: '5.รายงานบุคคลากร',
    subItems: [{
      title: 'Dashboard ข้อมูลบุคลากร',
      path: '/topics/Personnel_Dashboard/reports/Personnel_Dashboard'
    }, {
      title: 'จำนวนบุคลากรที่เชี่ยวชาญในด้านต่างๆ',
      path: '/topics/DiagnosticDashboards/reports/Diagnostic10-11'
    }, {
      title: 'บุคลากรที่จะเกษียณอายุในแต่ละด้านที่เชี่ยวชาญ ในแต่ละปี',
      path: '/topics/DiagnosticDashboards/reports/Diagnostic10-11'
    }],
  }, {
    title: '6.การพัฒนาบุคคลากร',
    subItems: [{
      title: 'แผนพัฒนาบุคคลากร',
      path: '/topics/PERSONNEL_PLAN/reports/PERSONNEL_PLAN'
    }, {
      title: 'จำนวนบุคคลากรที่ได้รับการฝึกอบรม',
      path: '/topics/Personnel_Training/reports/Personnel_Training'
    }, {
      title: 'จำนวนบุคคลากรที่ได้รับการฝึกอบรม',
      path: '/topics/Personnel_Training/reports/Personnel_Training'
    }, {
      title: 'จำนวนบุคคลากรที่ได้รับการฝึกอบรมแยกเป็นรายหลักสูตร',
      path: '/topics/Personnel_Training/reports/Personnel_Training'
    }, {
      title: 'จำนวนบุคคลากรที่ได้รับการฝึกอบรมด้านกฎหมายแยกตามรายหลักสูตร',
      path: '/topics/Law_Course/reports/Law_Course'
    }, {
      title: 'จำนวนบุคคลากรที่ได้รับการอบรมด้านเทคโนโลยีสารสนเทศแยกตามหลักสูตร',
      path: '/topics/IT_Course/reports/IT_Course'
    }],
  }, {
    title: '7.คุณภาพสายทาง',
    subItems: [{
      title: 'คุณภาพสายทางในแต่ละด้าน',
      path: '/topics/Route_Score/reports/Route_Score'
      // }, {
      //   title: 'ด้านความพึงพอใจด้านการให้บริการสายทาง',
      //   path: '/topics/Route_Satisfaction/reports/Route_Satisfaction'
      // }, {
      //   title: 'ด้านการให้ข้อมูลสายทาง',
      //   path: '/topics/Info_Route/reports/Info_Route'
      // }, {
      //   title: 'ด้านการความสวยงามของสายทาง',
      //   path: '/topics/Beautiful_Route/reports/Beautiful_Route'
      // }, {
      //   title: 'รายงานคะแนนพึงพอใจของผู้ใช้เส้นทาง ด้านความปลอดภัย',
      //   path: '/topics/Safety_Route_Satisfaction_Score/reports/Safety_Route_Satisfaction_Score'
      // }, {
      //   title: 'รายงานจำนวนเรื่องร้องเรียน',
      // path: '/topics/Safety_Route_Satisfaction_Score/reports/Safety_Route_Satisfaction_Score'
    }, {
      title: 'รายงานจำนวนอาสาสมัครแยกตามกิจกรรม',
      path: '/topics/Volunteer/reports/Volunteer'
    }],
  }, {
    title: '8.อุปกรณ์เครื่องจักรและยานพาหนะ',
    subItems: [{
      title: 'อุปกรณ์เครื่องจักรและยานพาหนะ',
      path: '/topics/Vehicle/reports/Vehicle'
    }],
  }, {
    title: '9.กลุ่มเทคโนโลยีสารสนเทศ',
    subItems: [{
      title: 'แผนงบประมาณครุภัณฑ์คอมพิวเตอร์',
      path: '/topics/IT_PLAN/reports/IT_PLAN'
    }, {
      title: 'รายงานจำนวนระบบงานคอมพิวเตอร์',
      path: '/topics/Equipment/reports/Equipment'
    }],
  }, {
    title: '10.ต้นทุนโลจิสติกส์',
    path: '/topics/Logistics_Environment_Index/reports/Logistics_Environment_Index',
    subItems: [],
  }, {
    title: '11.ดัชนีกายภาพและสภาพแวดล้อม',
    path: '/topics/Logistics_Environment_Index/reports/Logistics_Environment_Index',
    subItems: [],
  }]
}]




// const items = [{
//   title: '1.งบประมาณและโครงการ',
//   subItems: [{
//     title: 'แผนการเบิกจ่ายงบประมาณ',
//     path: '/topics/BUDGET_PLAN/reports/BUDGET_PLAN'
//   }, {
//     title: 'รายงานโครงการทั้งหมดของแต่ละสำนัก',
//     path: '/topics/All_Projects/reports/All_Projects'
//   }, {
//     title: 'รายงานผลการดำเนินการของแต่ละโครงการตามความคืบหน้าของโครงการ',
//     path: '/topics/Results_by_Progress/reports/Results_by_Progress'
//   }, {
//     title: 'รายงานแผนการดำเนินงานแต่ละโครงการในแต่ละเดือน',
//     path: '/topics/Monthly_Results_by_Progress/reports/Monthly_Results_by_Progress'
//   }, {
//     title: 'รายงานแผนการเบิกจ่ายในแต่ละเดือน',
//     path: '/topics/Monthly_Budget_Plan/reports/Monthly_Budget_Plan'
//   }, {
//     title: 'รายงานจัดซื้อจัดจ้างแยกรายสำนัก',
//     path: '/topics/Bureau_abbr_budget/reports/Bureau_abbr_budget'
//   }, {
//     title: 'รายงานโครงการสายทางที่ได้รับการบำรุงทางแยกตามแผนงาน',
//     path: '/topics/Bureau_abbr_budget_plan/reports/Bureau_abbr_budget_plan'
//   }, {
//     title: 'รายงานโครงการจัดซื้อจัดจ้าง',
//     path: '/topics/Procurement/reports/Procurement'
//   }, {
//     title: 'รายงานสถานะโครงการ',
//     path: '/topics/Project_Status/reports/Project_Status'
//   }, {
//     title: 'รายงานโครงการตามความคืบหน้าของโครงการ',
//     path: '/topics/DiagnosticDashboards/reports/Diagnostic01',
//     downloadSheet: 'Diagnostic01Data'
//   }, {
//     title: 'สาเหตุความล่าช้าของโครงการ',
//     // path: '/topics/1_/reports/10_'
//   }, {
//     title: 'จำนวนโครงการที่คาดว่าเสร็จในแต่ละเดือน',
//     path: '/topics/DiagnosticDashboards/reports/Diagnostic03'
//   }, {
//     title: 'รายงานโครงการที่มีโอกาสสำเร็จ',
//     path: '/topics/ProjectDelayPrediction/reports/ProjectDelayPrediction'
//   }, {
//     title: 'รายงานโครงการที่มีโอกาสล่าช้า',
//     // path: '/topics/1_/reports/13_'
//   }, {
//     title: 'สาเหตุของโครงการที่มีโอกาสล่าช้า',
//     // path: '/topics/1_/reports/14_'
//   }],
// }, {
//   title: '2.มาตรฐานสายทาง',
//   subItems: [{
//     title: 'รายงานจำนวนสายทางที่มีมาตรฐานตาม ค่า iri',
//     path: '/topics/iri/reports/iri'
//   // }, {
//   //   title: 'รายงานถนนที่มีระดับความเสี่ยงสูง',
//   //   path: '/topics/High_Risk_Roads/reports/High_Risk_Roads'
//   // }, {
//   //   title: 'จำนวนสายทางที่ต้องได้รับการปรับปรุง',
//   //   path: '/topics/grade_roads/reports/grade_roads'
//   }, {
//     title: 'จำนวนสายทางที่ต้องได้รับการปรับปรุง',
//     path: '/topics/DiagnosticDashboards/reports/Diagnostic05'
//   }],
// }, {
//   title: '3.กลุ่มอุบัติเหตุ',
//   subItems: [{
//     title: 'รายงานสายทางที่มีจำนวนอุบัติเหตุมากที่สุด',
//     path: '/topics/Road_Accidents/reports/Road_Accidents'
//   }, {
//     title: 'รายงานจำนวนผู้ประสบอุบัติเหตุแยกตามอาการบาดเจ็บแบ่งเป็นรายสำนัก',
//     path: '/topics/Road_Accidents/reports/Road_Accidents'
//   }, {
//     title: 'รายงานการแก้ปัญหาความปลอดภัย IMPROVEMENT_SPECIFY',
//     path: '/topics/Road_Accidents/reports/Road_Accidents'
//   }],
// }, {
//   title: '4.การบำรุงทาง',
//   subItems: [{
//     title: 'รายงานการบำรุงทาง',
//     path: '/topics/RoadMaintenance/reports/RoadMaintenance'
//   // }, {
//   //   title: 'จำนวนระยะทางที่ได้รับการบำรุงทาง',
//   //   path: '/topics/Road_Maintenance/reports/Road_Maintenance'
//   // }, {
//   //   title: 'งบประมาณในการบำรุงทางในแต่ละปี',
//   //   path: '/topics/Road_Maintenance/reports/Road_Maintenance'
//   }],
// }, {
//   title: '5.รายงานบุคคลากร',
//   subItems: [{
//     title: 'Dashboard ข้อมูลบุคลากร',
//     path: '/topics/Personnel_Dashboard/reports/Personnel_Dashboard'
//   }, {
//     title: 'จำนวนบุคลากรที่เชี่ยวชาญในด้านต่างๆ',
//     path: '/topics/DiagnosticDashboards/reports/Diagnostic10-11'
//   }, {
//     title: 'บุคลากรที่จะเกษียณอายุในแต่ละด้านที่เชี่ยวชาญ ในแต่ละปี',
//     path: '/topics/DiagnosticDashboards/reports/Diagnostic10-11'
//   }],
// }, {
//   title: '6.การพัฒนาบุคคลากร',
//   subItems: [{
//     title: 'แผนพัฒนาบุคคลากร',
//     path: '/topics/PERSONNEL_PLAN/reports/PERSONNEL_PLAN'
//   }, {
//     title: 'จำนวนบุคคลากรที่ได้รับการฝึกอบรม',
//     path: '/topics/Personnel_Training/reports/Personnel_Training'
//   }, {
//     title: 'จำนวนบุคคลากรที่ได้รับการฝึกอบรม',
//     path: '/topics/Personnel_Training/reports/Personnel_Training'
//   }, {
//     title: 'จำนวนบุคคลากรที่ได้รับการฝึกอบรมแยกเป็นรายหลักสูตร',
//     path: '/topics/Personnel_Training/reports/Personnel_Training'
//   }, {
//     title: 'จำนวนบุคคลากรที่ได้รับการฝึกอบรมด้านกฎหมายแยกตามรายหลักสูตร',
//     path: '/topics/Law_Course/reports/Law_Course'
//   }, {
//     title: 'จำนวนบุคคลากรที่ได้รับการอบรมด้านเทคโนโลยีสารสนเทศแยกตามหลักสูตร',
//     path: '/topics/IT_Course/reports/IT_Course'
//   }],
// }, {
//   title: '7.คุณภาพสายทาง',
//   subItems: [{
//     title: 'คุณภาพสายทางในแต่ละด้าน',
//     path: '/topics/Route_Score/reports/Route_Score'
//   // }, {
//   //   title: 'ด้านความพึงพอใจด้านการให้บริการสายทาง',
//   //   path: '/topics/Route_Satisfaction/reports/Route_Satisfaction'
//   // }, {
//   //   title: 'ด้านการให้ข้อมูลสายทาง',
//   //   path: '/topics/Info_Route/reports/Info_Route'
//   // }, {
//   //   title: 'ด้านการความสวยงามของสายทาง',
//   //   path: '/topics/Beautiful_Route/reports/Beautiful_Route'
//   // }, {
//   //   title: 'รายงานคะแนนพึงพอใจของผู้ใช้เส้นทาง ด้านความปลอดภัย',
//   //   path: '/topics/Safety_Route_Satisfaction_Score/reports/Safety_Route_Satisfaction_Score'
//   // }, {
//   //   title: 'รายงานจำนวนเรื่องร้องเรียน',
//     // path: '/topics/Safety_Route_Satisfaction_Score/reports/Safety_Route_Satisfaction_Score'
//   }, {
//     title: 'รายงานจำนวนอาสาสมัครแยกตามกิจกรรม',
//     path: '/topics/Volunteer/reports/Volunteer'
//   }],
// }, {
//   title: '8.อุปกรณ์เครื่องจักรและยานพาหนะ',
//   subItems: [
//     {
//       title: 'อุปกรณ์เครื่องจักรและยานพาหนะ',
//       path: '/topics/Vehicle/reports/Vehicle'
//     }
// //     {
// //     title: 'รายงานจำนวนเครื่องจักรแยกเป็นแต่ละประเภทของเครื่องจักร',
// //     path: '/topics/Equipment_Type/reports/Equipment_Type'
// // }, {
// //     title: 'รายงานสภาพยานพาหนะ',
// //     path: '/topics/Vehicle/reports/Vehicle'
// //   }, {
// //     title: 'รายงานงบประมาณการบำรุงรักษาเครื่องจักรในแต่ละปี',
// //     path: '/topics/Maintained_Budget/reports/Maintained_Budget'
// //   }, {
// //     title: 'รายงานจำนวนเครื่องจักรแยกตามหน่วยงาน',
// //     path: '/topics/Maintained_Budget/reports/Equipment_Per_Sector'
// //   }, {
// //     title: 'จำนวนเครื่องจักรที่ต้องได้รับการบำรุงรักษา',
// //     path: '/topics/Equipment_Per_Sector/reports/Equipment_Per_Sector'
// //     // path: '/topics/8_/reports/5_'
// //   }
// ],
// }, {
//   title: '9.กลุ่มเทคโนโลยีสารสนเทศ',
//   subItems: [{
//     title: 'แผนงบประมาณครุภัณฑ์คอมพิวเตอร์',
//     path: '/topics/IT_PLAN/reports/IT_PLAN'
//   }, {
//     title: 'รายงานจำนวนระบบงานคอมพิวเตอร์',
//     path: '/topics/Equipment/reports/Equipment'
//   }],
// }, {
//   title: '10.ต้นทุนโลจิสติกส์',
//   path: '/topics/Logistics_Environment_Index/reports/Logistics_Environment_Index',
//   subItems: [],
// }, {
//   title: '11.ดัชนีกายภาพและสภาพแวดล้อม',
//   path: '/topics/Logistics_Environment_Index/reports/Logistics_Environment_Index',
//   subItems: [],
// }]


// const getConfig = (items, workBook, view) =>  {
//   // const item = items.find(item => item.path === `/topics/${workBook}/reports/${view}`)
//   if (!items) return

//   for (const item of items) {
//     // Test current object
//     if (item.path === `/topics/${workBook}/reports/${view}`) {
//       return item
//     }

//     // Test children recursively
//     const child = getConfig(item.subItems, workBook, view);
//     if (child) {
//       return child
//     }
//     // if (child) { return child; }
//   }
// }
// const MenuItemLink = React.forwardRef((props, ref) => (
//   // console.log({ props }) || 
//   props.children
//     ? <Menu.Item ref={ref} {...props}>{props.children}</Menu.Item>
//     : <Menu.Item ref={ref} {...props}>{props.children}</Menu.Item>  
// ))

// const renderItems = (items) => items.map(item =>
//   <Link to={item.path} key={item.path} component={MenuItemLink} title={item.title} >
//     {item?.subItems?.length > 0 &&
//       <Menu.SubMenu>
//         {renderItems(item.subItems)}
//       </Menu.SubMenu>
//     }
//   </Link>
// )

// const SubMenuLink = React.forwardRef(({ navigate, ...props }, ref) => 
//   console.log({ props }) || 
//   <Menu.SubMenu key={props.title} ref={ref} {...props}>{props.children}</Menu.SubMenu>
// )

// const MenuItemLink = React.forwardRef(({ navigate, ...props }, ref) =>
//   <Menu.Item key={props.title} ref={ref} {...props}>{props.children}</Menu.Item>
// )

// const renderItems = ({ items }) => items.map(item =>
//   item?.subItems?.length > 0
//     ? <Link key={item.title} to={item.path} title={item.title} component={SubMenuLink}>
//       {renderItems({ items: item.subItems })}
//     </Link>
//     : <Link key={item.title} to={item.path} component={MenuItemLink}>
//       {item.title}
//     </Link>
// )

const menuItems = ({ items, onClick }) => items.map(item =>
  item?.subItems?.length > 0
    ? <Menu.SubMenu title={item.title} >
      {/* <MenuItems items={item.subItems} /> */}
      {menuItems({ items: item.subItems, onClick })}
    </Menu.SubMenu>
    : <Menu.Item onClick={onClick}>
      {item.path
        ? <Link to={item.path} target={item?.path?.pathname && '_blank'}>
          {item.title}
        </Link>
        : <span style={{ color: 'grey' }}>{item.title}</span>
      }
    </Menu.Item>
)


const Navigation = ({ items, onClick }) => <Menu
  defaultSelectedKeys={[]}
  defaultOpenKeys={[]}
  // mode="inline"
  mode="vertical"
// theme="dark"
// inlineCollapsed={this.state.collapsed}
>
  {/* <MenuItems items={items} /> */}
  {menuItems({ items, onClick })}
  {/* {renderItems({ items })} */}
  {/* {items.map(item =>
    item?.subItems?.length > 0
    ? <Menu.SubMenu title={item.title}>

    </Menu.SubMenu>
    : <Menu.Item>
      {item.title}
    </Menu.Item>
  )} */}
</Menu>

function App() {
  const [menuVisible, setMenuVisible] = useState(false)
  const { user, logout } = useAuth()
  const device = window.innerWidth < 500 ? 'phone' : 'desktop'
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <MenuUnfoldOutlined width={48} height={48} color="white" /> */}
      <Layout.Header className="header" style={{ background: 'rgb(24, 144, 255)' }}>
        <Button
          type="text"
          icon={<MenuOutlined />}
          onClick={() => setMenuVisible(true)}
          style={{ position: 'relative', marginLeft: '-2em', color: 'white' }}
        />
        <Link to="/"><img src="/logo.png" alt="DRR Logo" style={{ maxHeight: '100%', padding: '6px', margin: '0 6px' }} /></Link>
        {user && <Space style={{
          right: '0px',
          position: 'absolute',
          color: 'white',
          padding: '0 1em'
        }}>
          <span >
          {user.name}
          {/* <span onClick={logout}>[ Log Out ]</span> */}
          </span>
          <Button onClick={logout}>ออกจากระบบ</Button>
        </Space>}
      </Layout.Header>
      <Layout>
        <Drawer
          placement="left"
          title={<Link to="/"><img src="/logo.png" alt="DRR Logo" style={{ height: '80px', padding: '6px', margin: '0 6px' }} /></Link>}
          titleStyle={{ 'textAlign': 'center' }}
          visible={menuVisible}
          onClose={() => setMenuVisible(false)}
          bodyStyle={{ padding: 0 }}
          // footerStyle={{ padding: 0 }}
        >
          <Navigation items={items} onClick={() => setMenuVisible(false)} />
        </Drawer>

        {/* <Layout.Sider style={{ background: 'white' }} width={480}> */}
          {/* <Layout.Sider style={{ background: 'white' }} width={260}>
            <Navigation items={items} />
          </Layout.Sider> */}
        <Layout.Content style={{ overflow: 'initial' }}>
          <Switch>
            {/* <Route path="/login" component={Login} /> */}
            <Route path="/iot/:tbid" component={ThingsBoardRedirect} />

            {/* <Route path="/test">
              {() => <Tableau.View workBook={'Test'} view={'World'} options={{ 'Region Permission': 'Asia' }} />}
            </Route> */}
            <Route path="/topics/:topic/reports/:report">
              {({ match, location }) => {
                const { f } = qs.parse(location.search, { ignoreQueryPrefix: true })
                // console.log({ r })
                // const config = getConfig(items, match.params.topic, match.params.report)
                // console.log({ config })
                // if (config.downloadSheet) {
                //   return <Tableau.View workBook={match.params.topic} view={match.params.report}>
                //     {({ viz }) => console.log({ viz }, window.viz = viz) ?? <>
                //       <Tableau.Container />
                //       <Button onClick={() => viz.showExportCrossTabDialog(config.downloadSheet)}>downloadSheet CSV {config.downloadSheet}</Button>
                //       <Button onClick={() => viz.exportCrossTabToExcel(config.downloadSheet)}>downloadSheet XLSX {config.downloadSheet}</Button>
                //       <Button onClick={() => viz.showShareDialog()}>share XLSX</Button>
                //       </>}
                //   </Tableau.View>
                // }
                // console.log({ config })
                return <Tableau.View workBook={match.params.topic} options={{ device, ...f }} view={match.params.report} />
              }}
            </Route>
            <Route path="/">
              {() => <Tableau.View workBook={'homepage'} 
              options={{ device }}
              view={'1_Dashboardreport1'} />}
            </Route>
            <Route path="/tr_flood">
              {() => <Tableau.View workBook={'tr_flood'} view={'tr_flood'} options={{ device }} />}
            </Route>

          </Switch>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default App