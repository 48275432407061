import { useEffect } from "react"

const baseUrl = 'http://iot.sappasing.com'

// const dashboard = '573a1f20-ac9a-11eb-8893-4b67d781275d'
const dashboard = '573a1f20-ac9a-11eb-8893-4b67d781275d'
const id = 'stage2'

const ThingsBoardRedirect = ({ match }) => {
  const state = btoa(JSON.stringify([{
    id,
    params: {
      entityId: {
        entityType: "DEVICE",
        id: match.params.tbid
      }
    }
  }]))
  useEffect(() => {

    // eslint-disable-next-line no-restricted-globals
    location.href = `${baseUrl}/dashboards/${dashboard}?state=${state}`
  })
  // return `${baseUrl}/dashboards/${dashboard}?state=${state}`
  return 'Loading...'
}

export default ThingsBoardRedirect